export const sizes = {
  desktop: '1000px',
  mobile: '500px',
  mobileRes: '345px',
};

export const screens = {
  mobile: `(max-width: ${sizes.mobile})`,
};

export const colors = {
  gold: '#c6930a',
};
